import './Listing.css'

function Listing() {
    return(
        <div>
            <div className="heading">
                <h1>LISTING</h1>
            </div>
            <div className="list-page">
                <div className="list-container">
                    <iframe 
                        className="list-app"
                        title="property listing widget"
                        src="https://matrix.pillarnine.com/Matrix/public/IDX.aspx?idx=64752b9" 
                        width="100%" 
                        height="100%" 
                        frameborder="1" 
                        marginwidth="0" 
                        marginheight="0"
                        borderRadius="5%">
                    </iframe>
                </div>
            </div>
        </div>
    );
}

export default Listing;