import React from "react";

// CSS
import "./GuideTitleCard.css";

function GuideTitleCard(props) {
    const split_title = props.title.split(' ');
    return (
        <div className="title-card">
            <h1 className="title">{split_title[0]}</h1>
            <h1 className="title">{split_title[1]}</h1>
            <div className="title-content">
                <img className="guide-image" src={props.image} alt={props.alt} />
                <div className="guide-text">
                    <div>
                        <h3 className="guide-heading">{props.heading}</h3>
                        <p className="guide-description">{props.children}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GuideTitleCard;