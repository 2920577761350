import React from "react";
import HoriAccord from "../components/Horizontalcomp";

function Buyer(){
    return(
        <div className ="Buyer">
            <HoriAccord></HoriAccord>
        </div>
    );
}
export default Buyer;