import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// CSS
import './NavItem.css';

function NavItem(props) {
    const [show, setShow] = useState(false);
    const nav = useNavigate();
    const location = useLocation();

    // Store the children into an array and put them into a list element
    const childrenArray = Array.isArray(props.children) ? props.children : [props.children];
    const dropdownList = childrenArray.map((element, index) => 
        typeof element !== "undefined" ? 
        (
            <li 
                key={index} 
                className={`dropdown ${location.pathname === element.props.link ? 'active' : ''}`}
                onClick={() => {
                    nav(element.props.link);
                    props.onClick(); // Close the menu
                }}
            >
                {element}
            </li>
        ) : null
    );

    const hasValidDropdownItems = dropdownList.some(item => item !== null);

    return (
        <div 
            className={`nav-item ${location.pathname === props.link ? 'active' : ''}`}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
        >
            <h4 
                className="nav-name" 
                onClick={() => {
                    nav(props.link);
                    props.onClick(); // Close the menu
                }}
            >
                {props.name}
            </h4>
            {hasValidDropdownItems && show &&
                <ul className="dropdown-container">
                    {dropdownList}
                </ul>
            }
        </div>
    );
}

export default NavItem;
