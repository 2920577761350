import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// CREATES AN ACCORDIAN COMPONENT
//Add title property to the AccordComp tag to change the title
//Write your text inside the AccordComp

function AccordComp (props){
    return (
        <div>       
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                aria-controls="pantel1a-content"
                id="pantel1a-header">
                    <Typography variant="h5" 
                                color="#283b62" 
                                fontFamily='Open Sans, sans-serif'
                                fontWeight='bold'>
                                    {props.title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography color="#283b62" 
                                fontFamily='Open Sans, sans-serif'>
                                    {props.children}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>

    );
}
export default AccordComp;