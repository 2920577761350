import React from "react";

//CSS
import './Communityreport.css';

function Communityreport(){

    return(
        <div>
            <div className="about-container">
                <div className="heading">
                    <h1>Community</h1>
                    <h1>Report</h1>
                </div>
            </div>
            <div className="property-update-container">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfn0IANsUpGDPRYQifEBFv4IDtVh_r4qKA24q1hhjvSQEh0_g/viewform?embedded=true" 
                width="1500"
                scrolling="no"
                height="1645" 
                frameborder="0" 
                marginheight="0" 
                marginwidth="0">Loading…</iframe>
        </div>
        </div>

    );
}
export default Communityreport;