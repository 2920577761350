import React from "react";
import AccordComp from "../components/accordionComp";
import GuideTitleCard from "../components/GuideTitleCard";

// Image
import image from "../img/sellerguide.jpg";

// CSS
import './BuyersGuide.css';

function SellersGuide() {
    return (
      <div className="guide-page">
        <div className="guide-content">
          <GuideTitleCard title="SELLER'S GUIDE"
                          image={image}
                          heading="Getting Ready to Sell?">
            You’re selling your home. Maybe this is the first time 
            you’ve sold a home or maybe you’ve done this a few 
            times before. Either way, there are numerous things to 
            consider before and after you put your home on the 
            market. This Guide will help you get ready for every 
            step of the home-selling process.
          </GuideTitleCard>
          <div className="accordion_container">
            {/* 1st Accordion Layer*/}
            <AccordComp title="1. Getting Ready to Sell">
              Before listing your home for sale, take the following steps:<br />
            
              <strong><u>Prepare Documents</u></strong>:<br />
              <ul>
                <li><b>Real Property Report (RPR)</b>: In Alberta, sellers must provide an RPR with municipal compliance to buyers before closing. 
                  An RPR, prepared by an Alberta Land Surveyor, shows property boundaries and structures. Municipal compliance confirms that 
                  improvements meet Bylaws and Regulations.</li>
                <li><b>Condominium Documents</b>:
                  <ul>
                    <li>Reserve Fund Study</li>
                    <li>Reserve Fund Plan</li>
                    <li>Financial Statements (for reserve fund and operating fund)</li>
                    <li>Board Meeting Minutes</li>
                    <li>Annual General Meeting Minutes</li>
                    <li>Certificate of Insurance</li>
                  </ul>
                </li>
              </ul>

              <strong><u>Understand Market Conditions</u></strong>: 
              <ul>
                <li><b>Buyer’s Market</b>: High supply, low demand—buyers feel they get good deals.</li>
                <li><b>Seller’s Market</b>: High demand, low supply—buyers may overpay due to competition.</li>
                <li><b>Balanced Market</b>: Supply and demand are even.</li>
              </ul>

              <strong><u>Tenant’s Rights</u></strong>: If you have tenants, understand their rights and review 
                lease terms. Provide three months' notice if selling before a fixed-term lease ends.<br />

              <br />
              <strong><u>Review Your Mortgage</u></strong>: If you have a mortgage, check its terms:
              <ul>
                <li><b>Portable Mortgage</b>: Transferable to another property.</li>
                <li><b>Assumable Mortgage</b>: Buyer can take over your mortgage, but they must qualify.</li>
                <li>Consider payout penalties if not transferring the mortgage.</li>
              </ul>

            <strong><u>Consider Tax Implications</u></strong>: If the property isn’t your primary residence, 
              consult a tax professional to understand the tax consequences of the sale.<br />

            <br />
            <strong><u>Review Current Contracts</u></strong>: Check any home-related service contracts 
              (e.g., alarm system, hot water heater). Determine if they can be transferred or if you need to disclose 
              them to buyers
            </AccordComp>

            {/* 2nd Accordion Layer */}
            <AccordComp title="2. Finding a Licensee to Work With">
              Once you're ready to sell your home, the next step is to find a trustworthy real estate licensee. Here's how:<br />

              <br />
              <strong><u>Seek Referrals</u></strong>: Ask friends and neighbors for recommendations based on their experiences 
                with real estate licensees.<br />
          
              <br />
              <strong><u>Search Online</u></strong>: Explore personal websites and social media profiles of licensees to find 
                someone who suits your preferences.<br />

              <br />
              <strong><u>Verify Licensing</u></strong>: Ensure the licensee is authorized to work in Alberta by conducting a 
                public license search on the RECA website.<br />
            
              <br />
              <strong><u>Check Disciplinary History</u></strong>: Visit the RECA website's Complaints & Discipline tab to see 
                if the licensee has any disciplinary actions.<br />

              <br />
              <strong><u>Interview Candidates</u></strong>:
              <ul>
                <li>Ask about their experience, services, and approach to marketing and negotiations.</li>
                <li>Inquire about their specialty areas, fees, and how they handle conflicts of interest.</li>
                <li>Request references and interview multiple licensees to find the right fit.</li>
              </ul>

              <strong><u>Licensee Questions</u></strong>: Be prepared to discuss your motivation for selling, preferred timing, 
                and whether you're also planning to buy a home.
            </AccordComp>

            {/* 3rd Accordion Layer */}
            <AccordComp title="3. Working with a Real Estate Licensee">
              After selecting a real estate licensee, they'll provide you with RECA’s Consumer Relationships Guide (Guide), 
                a mandatory document that explains your legal relationship with the licensee. The Guide outlines three types 
                of relationships:
              <ul>
                <li><b>Common Law Agency</b>: The entire brokerage acts as your agent, even if you’re only working directly with one licensee.</li>
                <li><b>Designated Agency</b>: A specific licensee or team acts as your agent.</li>
                <li><b>Customer Relationship</b>: You work with the licensee without them acting as your agent or representing your best interests</li>
              </ul>
              Your licensee will ask you to sign an acknowledgment confirming you’ve read and discussed the Guide.<br />

              <br />
              <strong><u>Written Service Agreements</u></strong><br />            
              In Alberta, a written service agreement is required when you become a client of a residential real estate licensee. 
                These agreements clarify:
              <ul>
                <li>The relationship between you and the licensee</li>
                <li>The services the brokerage will provide</li>
                <li>The obligations and responsibilities of both parties</li>
                <li>Consent for the use and distribution of your personal information</li>
                <li>The method of compensation</li>
              </ul>
              You can negotiate terms such as the agreement’s duration, whether it's exclusive or non-exclusive, fees, services, 
                and clauses for early termination. However, note that while these items are negotiable, your licensee might have certain 
                restrictions, such as a minimum listing duration.<br />            
            
              <br />
              Ensure that any promised services are included in the written service agreement to guarantee you receive the services you expect.
            </AccordComp>

            {/* 4th Accordion Layer */}
            <AccordComp title="4. Completing the Seller Representation Agreement (Listing Agreement)">
              Your written service agreement, also known as a seller representation or listing agreement, outlines the 
              details of selling your property, including the listing price, inclusions/exclusions, and the responsibilities 
              of both you and your real estate licensee.<br />
            
              <br />
              <strong><u>Setting the Listing Price</u></strong>:
              <ul>
                <li>Your licensee will assist you in setting an appropriate price using tools like a Comparative Market 
                  Analysis (CMA), which compares recently sold properties. The final decision on the listing price is yours, 
                  with the goal of maximizing your sale in the shortest time.</li>
              </ul>

              <strong><u>Residential Measurement Standard (RMS)</u></strong>:
              <ul>
                <li>Your licensee must explain the RMS, which standardizes how property size is measured in Alberta. 
                  If you want to highlight features not included in the RMS, you can provide additional information, but ensure 
                  it's not misleading.</li>
              </ul>
            
              <strong><u>Additional Considerations in the Seller Representation Agreement</u></strong>:
              <ul>
                <li><b>Dower Rights</b>: If married, but your spouse isn’t on the property title, you may need their consent to sell.</li>
                <li><b>Possession Date</b>: You can specify a preferred possession date or indicate flexibility.</li>
                <li><b>Attached/Unattached Goods</b>:
                  <ul>
                    <li><b>Attached goods</b> (e.g., built-in appliances) typically stay with the property unless excluded.</li>
                    <li><b>Unattached goods</b> (e.g., wall art) are usually taken by the seller unless included in the sale.</li>
                  </ul>
                </li>
                <li><b>Inclusions/Exclusions</b>: Clearly specify what items are included or excluded in the sale to avoid misunderstandings.</li>
                <li><b>Material Latent Defects</b>: Disclose any hidden defects that affect the property's safety, livability, or intended use. 
                  This is a legal requirement.</li>
                <li><b>Stigmatized Properties</b>: Issues unrelated to the property's physical condition (e.g., a death in the home) 
                  do not need to be disclosed. If asked, you must answer honestly if you choose to respond.</li>
              </ul>

              These considerations ensure transparency and clarity throughout the selling process.
            </AccordComp>

            {/* 5th Accordion Layer */}
            <AccordComp title="5. Conflicts of Interest">
              In rare cases, you may encounter a conflict of interest when working with a real estate 
                licensee. This occurs when the licensee's interests or relationships clash with your best interests. 
                Examples include:
            
              <ul>
                <li><b>Personal Relationship with Buyer</b>: If the buyer is related to your licensee, they might struggle to 
                  prioritize your needs over their family connection.</li>
                <li><b>Licensee as Buyer</b>: If your licensee wants to buy your property, they can't fully represent your best 
                  interests while also pursuing their own.</li>
                <li><b>Dual Representation</b>: If your licensee represents both you and the buyer, they face a conflict because 
                  they owe undivided loyalty to both parties, which is not possible.</li>
              </ul>

              When a conflict arises, the licensee must:
              <ul>
                <li><b>Disclose Details</b>: Inform you of all relevant details about the conflict.</li>
                <li><b>Explain the Conflict</b>: Clarify why the conflict exists and how it impacts you.</li>
                <li><b>Advise Independent Advice</b>: Recommend that you seek independent advice.</li>
              </ul>

              <strong><u>Transaction Brokerage</u></strong><br />
              When a conflict arises due to dual representation (the licensee representing both you and the buyer), transaction 
                brokerage is an option. Here, the licensee acts as a neutral facilitator rather than an agent, treating both parties 
                fairly and impartially. The licensee no longer owes undivided loyalty to either party. <br />

              <br />
              If you agree to transaction brokerage, you and the buyer will sign an "Agreement to Represent Both Buyer and Seller" 
                form, which outlines the new terms of the relationship. If either party doesn’t agree to this arrangement, alternative 
                options will be presented.<br />

              <br />
              Ultimately, once a conflict of interest is disclosed, you decide how to proceed. You might seek legal advice, continue 
                with the same licensee under transaction brokerage, or opt for a different licensee. Remember, your licensee cannot continue 
                to provide services if a conflict of interest exists unless you give written informed consent.
            </AccordComp>

            {/* 6th Accordion Layer */}
            <AccordComp title="6. Considering Offers">
              When navigating the offer process in a real estate transaction, understanding key aspects of offers is crucial:<br />

              <br />
              <strong><u>Possession Date</u></strong>: The agreed date when the buyer takes ownership of the property.<br />

              <br />
              <strong><u>Inclusions and Exclusions</u></strong>: Items included or excluded in the sale, like appliances or fixtures. 
                Ensure these align with what was listed.<br />

              <br />
              <strong><u>Time for Acceptance</u></strong>: The offer's expiration date/time, after which the buyer is not obligated 
              to keep the offer open.<br />
            
              <br />
              <strong><u>Pre-possession Inspection</u></strong>: Allows the buyer to inspect the property before finalizing the 
                sale to ensure its condition hasn't changed.<br />

              <br />
              <strong><u>Conditions</u></strong>:
              <ul>
                <li><b>Common Conditions</b>: Often include financing and home inspection. These protect the buyer’s interests and 
                  must have an expiration date.</li>
                <li><b>Waiving Conditions</b>: The sale is not final until the buyer waives their conditions in writing by the 
                  specified date.</li>
              </ul>

              <strong><u>Deposits</u></strong>:<br />
              Demonstrates the buyer’s seriousness. The contract will specify who holds the deposit in trust—typically a brokerage or lawyer.<br />

              <br />
              <strong><u>Communication for Acceptance</u></strong>:<br />
              The offer should clearly state how communication should occur (in-person, fax, email).<br />

              <br />
              <strong><u>Holdbacks</u></strong>: Part of the purchase price withheld until the seller completes agreed tasks 
                (e.g., renovations, repairs). Must be agreed upon in the contract.<br />

              <br />            
              Understanding these components ensures you are better prepared during the offer process and helps you make informed decisions.
            </AccordComp>

            {/* 7th Accordion Layer */}
            <AccordComp title="7. Negotiations">
              When a buyer submits an Offer to Purchase, it's common for some negotiation to take place. Here's how to navigate 
              this process:<br />

              <br/>
              <strong><u>Accept the Offer</u></strong>: <br />
              <ul>
                <li>If unconditional: Congratulations, your home is sold!</li>
                <li>If conditional: The buyer's agent will work to satisfy and waive the conditions by the expiry date.</li>
              </ul>

              <strong><u>Reject the Offer</u></strong>: <br />
              <ul>
                <li>The buyer can choose to submit a new offer or move on to another property.</li>
              </ul>

              <strong><u>Make a Counter Offer</u></strong>: <br />
              <ul>
                <li>Discuss with your agent which terms (price, possession date, inclusions, etc.) you want to change 
                  and propose a counter offer.</li>
              </ul>

              <strong><u>Ignore the Offer</u></strong>: <br />
              <ul>
                <li>Similar to rejection. The buyer may submit a new offer or move on. If there’s no expiry date on the original 
                  offer, it remains open unless withdrawn by the buyer.</li>
              </ul>

              Your real estate licensee will guide you through these options, weighing the pros and cons of each.<br />

              <br />
              <strong><u>Multiple Offers</u></strong>:<br />
              In a multiple offer situation, where several buyers submit offers on your property at the same time, you have significant 
                control over the process.<br />

              <ul>
                <li><b>Notification</b>: Your agent should inform you immediately of the multiple offers.</li>
                <li><b>Review</b>: Look at each offer before making any decisions.</li>
                <li><b>Buyers’ Options</b>: Buyers can increase, withdraw, or revise their offers.</li>
                <li><b>Disclosure</b>: It’s your choice whether to disclose the existence of multiple offers to the buyers.</li>
                <li><b>Confidentiality</b>: Some buyers may include a term preventing you from disclosing their offer details to 
                  others. You must respect this.</li>
                <li><b>Condition of Sale</b>: If a buyer’s offer is conditional on selling their current property, consider how this 
                  impacts your decision.</li>
              </ul>

              Your real estate licensee will help you manage the multiple offers, present all offers within the same timeframe, and follow your 
                lawful instructions. Ultimately, how you proceed is up to you, with your agent providing guidance to ensure the best outcome.
            </AccordComp>

            {/* 8th Accordion Layer */}
            <AccordComp title="8. Finalizing a Purchase and Preparing for Possession">
              Congratulations! Once the buyer waives their conditions, your home is officially sold. Now, it's just a matter of 
                preparing for possession day.<br />

              <br/>
              <strong><u>Hiring a Real Estate Lawyer</u></strong>:<br />
              To ensure everything goes smoothly, you'll need to hire a real estate lawyer to finalize the transaction. It's wise to 
                contact a lawyer well before possession day, giving them ample time to review and prepare the necessary documents. 
                Your real estate licensee and mortgage broker will need your lawyer’s contact information as they’ll be providing some 
                documents directly to the lawyer.<br />
            
              <br/>
              <strong><u>Meeting with Your Lawyer</u></strong>:<br />

              <br/>
              <strong><u>Hiring a Real Estate Lawyer</u></strong>:<br />
              A few days before possession, you’ll meet with your lawyer to finalize the legal aspects of the sale. During this 
                meeting, your lawyer will:
              <ul>
                <li>Review and explain all legal documents</li>
                <li>Discuss the title transfer process</li>
                <li>Outline closing costs and disbursements</li>
                <li>Go over mortgage payout details (if applicable)</li>
                <li>Have you sign documents to transfer the property’s title to the buyer upon receiving the purchase funds</li>
              </ul>
              <strong>Don’t forget</strong> to bring photo ID, and possibly two pieces of identification (such as a passport, driver’s license, or 
                credit card) to this meeting. Confirm with your lawyer what specific forms of ID they require.<br />

              <br/>
              <strong><u>Preparing for Possession Day</u></strong>:<br />
              While waiting for possession day, you can use the time to:
              <ul>
                <li><b>Forward your mail</b>: Arrange with Canada Post to have your mail forwarded to your new address.</li>
                <li><b>Disconnect utilities</b>: Schedule the disconnection of electricity, gas, cable, and other services.</li>
                <li><b>Hire a moving company</b>: If needed, book a moving company to assist with your move.</li>
              </ul>

              <br/>
              <strong><u>Maintaining Your Home</u></strong>:<br />
              Even though you’ve sold your home, your responsibilities as a homeowner continue until possession day. This includes:
              <ul>
                <li>Maintaining the property (e.g., mowing the lawn, shoveling sidewalks)</li>
                <li>Keeping your home insurance active, even if you vacate the property before the buyer takes possession. Discuss 
                  any changes in occupancy with your insurance provider to ensure continued coverage.</li>
              </ul>

              <strong><u>What If the Buyer Backs Out?</u></strong><br />
              Although rare, there’s a small chance the buyer might back out of the deal close to possession day, often due to 
                unforeseen changes in their financial situation, health issues, or mortgage financing falling through. If this happens, 
                it becomes a legal matter. While your real estate professional can provide some guidance, you will need to seek legal 
                advice to understand your rights and options in this situation.<br />

              <br />
              By staying on top of these details and maintaining open communication with your lawyer and real estate professional, 
                you’ll be well-prepared for a smooth closing and transition to your new home.
            </AccordComp>

            {/* 9th Accordion Layer */}
            <AccordComp title="9. What to Expect on Possession Day">
              On possession day, the transfer of your property to the buyer becomes official. Typically, possession occurs around 
                noon, and this is when the buyer receives the keys. Here's what to expect:<br />

              <br />
              <strong><u>Pre-Possession Inspection</u></strong>:<br />
              If the buyer included a pre-possession inspection as a term in their Offer to Purchase, your real estate licensee 
                will coordinate this with the buyer’s licensee. This inspection ensures that:
              <ul>
                <li><b>Attached goods and inclusions</b> are still in place.</li>
                <li><b>Appliances</b> are in the same working order as when the buyer made their offer.</li>
                <li>The <b>property’s condition</b> is similar to when the buyer last viewed it.</li>
              </ul>

              <strong><u>Cleanliness</u></strong>:<br />
              While there’s no specific standard for cleanliness, it’s expected that the property will be in a reasonably clean 
              state. The buyer anticipates finding the property in the same condition as when they viewed it, so take reasonable 
              steps to clean and prepare the home.<br />

              <br />
              <strong><u>Handling Issues Discovered During Inspection</u></strong>:<br />
              If the buyer finds discrepancies during the pre-possession inspection (such as missing items or a decline in the 
                property’s condition), their real estate licensee will contact your licensee to discuss possible remedies. Ideally, 
                these issues should be resolved before the mortgage funds are released and the keys are handed over. If a resolution 
                cannot be reached, your lawyer may need to get involved, potentially delaying possession.<br />

              <br />
              <strong><u>Post-Possession Issues</u></strong>:<br />
              If the buyer discovers issues after taking possession, such as missing items or the property not being in the expected 
                condition, their licensee will likely reach out to yours to find a resolution. If an agreement cannot be reached, you 
                may need to involve your lawyer, as it becomes a legal matter. At this point, the buyer has already advanced the purchase 
                funds, so resolving the issue amicably is in both parties’ best interests.<br />

              <br />
              <strong><u>Final Considerations</u></strong>:<br />
              To avoid any complications, it’s important to:
              <ul>
                <li>Ensure all agreed-upon items are in place and in good working order.</li>
                <li>Keep the property clean and in the same condition as when the buyer made their offer.</li>
                <li>Communicate any potential issues early to prevent delays or legal complications.</li>
              </ul>
              By addressing these details, you can help ensure a smooth transition and avoid any disputes on or after possession day.
            </AccordComp>
          </div>
        </div>
      </div>   
    );
}

export default SellersGuide;