import HoriAccord3 from "../components/Horizontalcomp3"

function Home() {
    return ( 
        <>
            <HoriAccord3></HoriAccord3>
        </>
    );
}

export default Home;

