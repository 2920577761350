import './Horizontalcomp.css';
import { useNavigate } from 'react-router-dom';

function HoriAccord (){
    const nav= useNavigate()

    return(
        <div className="containerhori">
            <div className="guide-wrap">
                <div className="guide guide-1" 
                     style={{"background-image":"url('https://images.unsplash.com/photo-1606787366502-801098a5eca5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80')"}}
                     onClick={() => nav("/buyer-s-form")}>
                    <div className="name-container">
                        <h2 className="section-name">Buyers Form</h2>
                    </div>
                </div>
                <div className="guide guide-2" 
                     style={{"background-image":"url('https://images.unsplash.com/photo-1626266061368-46a8f578ddd6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80')"}}
                     onClick={()=>nav("/mortgage-calculator")}>
                    <div className="name-container">
                        <h2 className="section-name">Mortgage<br></br>Calculator</h2>
                    </div>        
                </div>
                <div class="guide guide-3" 
                     style={{"background-image":"url('https://images.unsplash.com/photo-1555192881-d1bee8bdfedf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1171&q=80"}}
                     onClick={()=>nav("/search")}>
                    <div className="name-container">
                        <h2 className="section-name">Search</h2>
                    </div>  
                </div>
                <div class="guide guide-4" 
                     style={{"background-image":"url('https://images.unsplash.com/photo-1598960087461-556c5a1f864a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"}}
                     onClick={()=>nav("/buyer-s-guide")}>
                    <div className="name-container">
                        <h2 className="section-name">Buyers guide</h2>
                    </div>  
                </div>
                <div class="guide guide-5" 
                     style={{"background-image":"url('https://images.unsplash.com/photo-1605256585681-455837661b18?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"}}
                     onClick={()=>nav("/property-updates")}>
                    <div className="name-container">
                        <h2 className="section-name">Property <br></br>Updates</h2>
                    </div>  
                </div>
            </div>
        </div>
    );
}
export default HoriAccord;