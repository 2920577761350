import { Link } from "react-router-dom";
import './NavDropdownitem.css';

function NavDropdownItem({ link, children, onClick }) {
    return (
        <Link
            className="dropdown-link"
            to={link}
            onClick={onClick} // Call onClick when the link is clicked
        >
            {children}
        </Link>
    );
}

export default NavDropdownItem;
