import React from "react";
import TextField from '@mui/material/TextField';

//Images
import contactPhoto from '../img/judypfp.jpg';

//CSS 
import './Contact.css'

function Contact() {
    return (
    <div className="contact-page">
        <div className="heading">
            <h1>CONTACT</h1>
        </div>
        <div className="contact-container1">
            <div className="contact-info">
                <h1>Judy Liu</h1>
                <p>Residential/ Property Investment<br />
                    Real Estate/ Leasing Agent
                </p>
                <br />
                <h6><strong>CONNECT WITH ME THROUGH:</strong></h6>
                <p>Email: jliu.estates@gmail.com
                <br />Tel: 1.403.397.2138
                </p>
            </div>
            <div className="photo-container" >
                <img className= "contact-photo" src={contactPhoto} alt="Realtor headshot" />
            </div>
            <div className="contact-address">
                <h6><strong>ADDRESS:</strong></h6>
                <p>
                    #110, 602 12th Ave SW<br/>
                    Calgary AB, T2R 1J3<br/>
                    T. 403 520 5220<br/>
                </p>
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2508.60362985593!2d-114.0745059!3d51.041941099999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53716fe209640cf5%3A0xce0ee0033d33bf1f!2s602%2012%20Ave%20SW%20%23110%2C%20Calgary%2C%20AB%20T2R%201J3!5e0!3m2!1sen!2sca!4v1693687870108!5m2!1sen!2sca" 
                    title="Location of Hope Street"
                    className="map-border"
                    allowFullScreen="" 
                    loading="lazy" 
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="form-container">
                <h6><strong>ALTERNATIVELY, YOU CAN FILL IN THE FOLLOWING CONTACT FORM BELOW:</strong></h6>
                <form action="https://formsubmit.co/eb4c1c0e99693615e854cac5776af697" method="POST">
                    <input type="hidden" name="_captcha" value="false"/>
                    <input type="hidden" name="_next" value="http://jliuestates.ca/thank-you"/> 
                    <TextField id="outlined-basic" variant="outlined" label="First Name"    name="First Name"                 margin="dense" required />
                    <TextField id="outlined-basic" variant="outlined" label="Last Name"     name="Last Name"                  margin="dense" required />
                    <TextField id="outlined-basic" variant="outlined" label="Phone Number"  name="Phone Number"  type="tel"   margin="dense" required />
                    <TextField id="outlined-basic" variant="outlined" label="Email"         name="Email"         type="email" margin="dense" required />
                    <TextField
                        id="outlined-multiline-static"
                        label="Write Your Request"
                        multiline
                        rows={4}
                        name="Request"
                        margin="dense"
                    />
                    <button type="submit" className="contact-button submit-button">SUBMIT NOW</button>
                </form> 
            </div>
        </div>
    </div>

    );
}

export default Contact;