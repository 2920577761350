import React, { useEffect } from 'react';

const MortgageCalculator = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://www.ratehub.ca/assets/js/widget-loader.js';
    // script.async = true;

    const widgetDiv = document.querySelector('[data-widget="calc-payment"]');

    if (widgetDiv) {
      widgetDiv.appendChild(script);
    }

    return () => {
      if (widgetDiv) {
        widgetDiv.removeChild(script);
      }
    };
  }, []);

  return (
    <div>
      <h2 style={{ fontFamily: 'stagsans, arial, helvetica, sans-serif', fontSize: '1.6em', fontWeight: 400, marginTop: "20px", textDecoration: 'none', color: '#A3C139' }}>
        Mortgage payment calculator
      </h2>
      <div className="widget" data-widget="calc-payment" data-lang="en">
        <div style={{ textAlign: 'right' }}>
          <a href="https://www.ratehub.ca/" style={{ display: 'inline-block', width: '80px', marginTop: '.5em' }}>
            <img src="https://www.ratehub.ca/assets/images/widget-logo.png" style={{ width: '100%' }} alt="Ratehub.ca logo" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default MortgageCalculator;
