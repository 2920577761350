import React from "react";
import HoriAccord2 from "../components/Horizontalcomp2";

function Seller(){
    //TODO: Add form below the accordion and link to Navibar
    
    return(
        <div className ="Sellers">
            <HoriAccord2></HoriAccord2>
        </div>
    );
}
export default Seller;