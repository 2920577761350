import React from "react";
import AccordComp from "../components/accordionComp";
import GuideTitleCard from "../components/GuideTitleCard";

// Image
import image from "../img/buyerguide.jpg";

// CSS
import './BuyersGuide.css';

function BuyersGuide() {
    return (
      <div className="guide-page">
        <div className="guide-content">
          <GuideTitleCard title="BUYER'S GUIDE"
                          image={image}
                          heading="Congratulations! You've decided to buy a home">
            This may be your first home, or you have a growing family 
            or maybe you’re about to retire. Regardless of your stage in 
            life, buying a home is a significant financial commitment. 
            This Guide will help you get ready for every step of the 
            home-buying process.
          </GuideTitleCard>
          <div className="accordion_container">
          {/* Drop down to edit the texts */}
          <AccordComp title="1. Are you financially ready to buy a home?">
          Get a sense of your current monthly expenses and consider the following:<br></br>

          <br></br><u>Down Payment:</u> A down payment is the part of the purchase price the buyer pays in cash rather than financing 
          with a mortgage. Buyers typically need a minimum 5% of the purchase price as a down payment. If your down payment is less
          than 20% of the purchase price, you need a high ratio mortgage and it must be your primary residence. Lenders require 
          borrowers to obtain mortgage insurance for high ratio mortgage, since they can be riskier for financial institutions. 
          If your down payment is 20% of the purchase price or more, you do not require mortgage insurance.<br></br>

          <br></br><u>Deposit funds:</u> You need to provide a deposit with your offer to purchase. Your deposit funds are typically 
          part of your down payment.<br></br>

          <br></br><u>Closing costs:</u> Closing costs are separate from your deposit and down payment, and are typically due on possession date, 
          when the real estate transaction is complete and the property is yours. Closing costs include lawyer fees, property tax adjustments, 
          title insurance (if any), etc. Budget a couple thousand dollars on top of the purchase price as closing costs.<br></br>

          <br></br>**Remember being approved for a mortgage of a certain amount doesn’t mean you have to spend that much. 
          You want to budget for unplanned expenses, interest rates rise, or times when you’re off of work.<br></br>

          <br></br><u>Get a sense of market conditions:</u> Market conditions affect home prices. 
          Sellers want to get as much for their property as they can and buyers want to pay as little as they can; 
          the market conditions will dictate who has a stronger negotiating position. News articles often refer to real estate market conditions.
          <br></br>
          <ul>
            <li><u>buyer’s market</u>– where property supply is strong and buyer demand is weak. Buyers usually receive a good deal.</li>
            <li><u>seller’s market</u> – where buyer demand is strong and property supply is weak. The market is in favor of the sellers 
                  as buyers compete for a limited supply of properties. </li>
            <li><u>balanced market</u> – where demand from buyers is keeping pace with the supply of properties for sale.</li>
          </ul>
          </AccordComp>

          <AccordComp title="2. Working with a Mortgage Broker">
          Most buyers need financing (a mortgage) to purchase a home. The two most common sources of a mortgage are:<br></br>
          <ul>
            <li><u>Banks:</u> Typically, only offer their own mortgage products.</li>
            <li><u>Mortgage brokers:</u> can source mortgage products from different lenders. Interview questions for 
              a mortgage broker include:<br></br>
              <ul>
                <li>are you a licensed mortgage broker?</li><li>do you represent the borrower, the lender or both?</li><li>do I need to sign a contract?</li>
                <li>what service do you provide and how will you help me?</li><li>do you charge borrowers a fee?</li><li>how do you recieve compensation for your services?</li>
                <li>how many lenders do you work with?</li><li>was most of your business done through one lender last year?</li>
              </ul>
            </li>
          </ul>
          ​It is important to understand the relationship with their mortgage broker. A mortgage brokerage may:<br></br>
          <ul><li>represent a borrower (you);</li><li>represent the lender; or,</li><li>act as an intermediary</li>
          </ul>
          ​**Mortgage brokers have a responsibility to clearly explain their role to borrowers they’re working with.<br></br>
          <br></br><b>When they represent borrowers (you)</b>, have the duty to:
          <ul>
            <li>act in your best interests at all times</li>
            <li>owe you general, fiduciary, and regulatory obligations. Which includes:
              <ul>
                <li>undivided loyalty</li><li>cofidentiality</li><li>full disclosure</li><li>obedience</li><li>reasonable care and skill</li>
                <li>full accounting</li>
              </ul>
            </li>
          </ul>
          They will recommend financing options to you, advocate on your behalf, and provide you with confidential advice.<br></br>
          <br></br><b>When they are representing the lender</b>
          <br></br>When a mortgage brokerage is representing the lender as their client, they will 
          be acting in the lender’s best interests, not yours.<br></br>
          <br></br>Mortgage brokerages can still work with you; however, they will treat you as 
          a customer and must:<br></br>
          <ul>
            <li>treat you honestly and act with reasonable care and skill</li>
            <li>gather information on the property you want to finance and on your financial situation</li>
            <li>explain the lender’s options to you</li>
            <li>complete the necessary documents and submit them to the lender</li>
            <li>tell you about the transaction’s progress and pass along any communications from the lender to you</li>
          </ul>
          <b>When they are acting as an intermediary</b><br></br>
          A mortgage brokerage may act as an intermediary between borrower (you) and potential lenders. In this case,<b> both you 
          and the lender are customers of the mortgage brokerage.</b><br></br>
          The brokerage will facilitate the mortgage deal by gathering information, explaining the options, completing the necessary
          documents and keeping both sides apprised of the deal’s progress. They will not act to the benefit or detriment of you or the lender(s).
          <br></br>
          <br></br>Alberta mortgage brokerages often work as intermediaries when working with residential borrowers. It is the mortgage 
          brokerage’s decision.
          </AccordComp>

          <AccordComp title="3. Understanding and Securing Financing">
          Your mortgage broker has tools to help you in your home search. Before you go house shopping, you want to know how much of a 
          mortgage your income will support, and what affect your current debt load will have on a possible mortgage amount. 
          You can find this out by getting a mortgage pre-qualification.<br></br>
          <br></br><u>Mortgage pre-qualification:</u> is a tentative approval from a lender for a mortgage based on your qualifications
          (i.e. income, down payment amount) made in advance of a real estate purchase. Pre-qualification will provide you with 
          the information needed to know how much of a mortgage you will likely be approved for and ensure you're shopping for homes you can afford.
          <br></br>
          <br></br><b>A pre-qualification is not the same as being approved for the mortgage, it is not a guaranteed.</b> Once you make an offer to buy 
          a property, you will formally apply for a mortgage, and you will have to submit supporting documentation that confirms your financial 
          position. A lender might choose not to approve you for a mortgage after it more closely assesses you and the property.<br></br>
          <br></br>**While looking for a home, try to maintain your financial situation. That means it’s not a good time to change employment or take 
          on additional debt (vehicles, etc.).<br></br>
          <br></br>You may need to have certain documents available when you seek a mortgage pre-qualification, and you will definitely need them when 
          you apply for the mortgage – these include:
          <ul>
            <li>proof of employment (for example, a letter from your employer)</li><li>most recent Canada Revenue Agency Notice of Assessment</li>
            <li>confirmation of income (for example, pay stubs or a T4)</li><li>gift letter (if you’re using a gift as your down payment)</li>
          </ul>
          Lenders will review these documents, along with other items such as your credit report, in order to determine how much of a mortgage you can afford. 
          They will also review a couple of key calculations to make that determination:<br></br>
          <ul>
            <li><u>Gross Debt Service (GDS) Ratio:</u> Lenders use a borrower’s GDS to determine whether the borrower has an acceptable debt level. 
            Your GDS is the percentage of your gross monthly income required to cover payments associated with housing. Payments include mortgage 
            principal, interest, property taxes, heating, and half of your condominium fees (if applicable). Generally, for a lender to consider 
            you for a mortgage, your <b>GDS should be no more than 35% of your gross monthly income.</b></li>
            <li><u>Total Debt Service (TDS) Ratio:</u> Lenders also review your TDS ratio. Your TDS is the percentage of your gross monthly income
            needed to cover your monthly debt load, which includes your housing costs, plus all of your other debt payments (car loan or lease,
            student loans, lines of credits, etc.). Your <b>TDS should not be more than 42% of your gross monthly income.</b></li>
          </ul>
          </AccordComp>

          <AccordComp title="4. Conflicts of interest">
          Conflicts of Interest arises when there is a real or apparent incompatibility between a real estate professional’s 
          interests and your interests.<br></br>
          <br></br>Some examples of common conflicts that may arise in a real estate transaction:
          <ul>
            <li>if you find a home to buy and the seller is related to your real estate professional. 
            This is a conflict of interest because your real estate professional is supposed to put your needs 
            above all else and if they’re related to the seller, it could be difficult for them to do so</li>
            <li>you want to buy a property that your real estate professional owns. This is a conflict of interest 
            because your real estate professional is supposed to represent your best interests, and they won’t be able to 
            fulfill that fiduciary duty if it’s their property you want to buy</li>
            <li>you find a property you want to buy, but your real estate professional also represents another buyer who wants
            to buy the same property. This is a conflict of interest because your real estate professional has the same responsibility
            to both buyer clients; to put their needs above all else. Your real estate professional cannot act in the best interests of 
            both of their buyer clients.</li>
            <li>you find a property you want to buy, but your real estate professional also represents the seller. This is a conflict of 
            interest because your real estate professional has the same responsibility of undivided loyalty to both you and the seller, 
            and cannot act in the best interests of both you and their seller client.</li>
          </ul>
          ​Real estate professionals have an obligation to avoid, and disclose any conflicts of interest to you as soon as they arise. 
          That disclosure requires them to:
          <ul>
            <li>give you all the details they know about the conflict</li><li>explain why they believe they are in a conflict or potential 
              conflict of interest</li><li>describe how the conflict affects you</li><li>advise you to obtain independent advice</li>
          </ul>
          <u>Transaction brokerage</u> is a way to deal with the one specific type of conflict of interest that arises when your real estate 
          professional also represents the seller of a home you want to buy. <b>It allows your real estate professional to work with both
          you and the seller client in the same transaction.</b><br></br>
          <br></br><b>In transaction brokerage</b>, your <b>real estate professional becomes a transaction facilitator</b>, and 
          <b> treats you and the seller in an even-handed, objective, and impartial manner.</b> The real estate professional no longer 
          owes you or the seller undivided loyalty.<br></br>
          <br></br>If you and the seller agree to proceed in transaction brokerage, you will <b> both have to sign an Agreement to Represent
          Both Buyer and Seller form</b>, which ends the sole agency representation for you and the seller, and it sets out the terms of 
          the new relationship between your real estate professional, you, and the seller. If you or the seller don’t agree to transaction 
          brokerage, your real estate professional will present you with other options.<br></br>
          <br></br>Once you are aware of any conflict of interest, it’s up to you how you want to proceed. You may want to get legal advice
          or you may want to proceed with a different real estate professional.<br></br>
          <br></br><b>Your real estate professional cannot provide services to you if they are in a conflict of interest unless you give 
          them your written informed consent.</b>
          </AccordComp>

          <AccordComp title="5. Finding the Right Place">
          Now it’s time to find the perfect home. Consider:
          <ul>
            <li>location</li><li>home style – condo apartment, condo townhouse, single family home</li>
            <li>number of bedrooms</li><li>number of bathrooms</li><li>features – garage, yard, basement suite</li>
            <li>inclusions – appliances</li><li>price</li><li>size</li>
          </ul>
          **It’s a good idea to make a list of your needs and your wants to better focus your search.<br></br>
          <br></br><b>Property size</b> is one common primary consideration for buyers. Real estate professionals in Alberta <b>must use 
          the Residential Measurement Standard (RMS) when describing a residential property’s size.</b><br></br>
          <br></br><u>Residential Measurement Standards (RMS)</u>: offers a consistent means of representing the property’s above grade space,
          and it sets out what parts of a property can be included in its measured-area. If property size is important to you, 
          tell your representative, and take steps to verify the size rather than relying on the seller’s representation.<br></br>
          <br></br>**Property’s size isn’t the only thing sellers are using to set a listing price for their home. The price of a 
          home also depends on location features, décor, and upkeep.<br></br>
          <br></br><b>Other considerations</b><br></br>Beyond the size, location, and specific features of a home – there are additional things 
          to think about and ask about as you look at properties:<br></br>
          <br></br><u>Material latent defects</u>: are defects a person cannot discover with reasonable care during an inspection. They include defects that:
          <ul>
            <li>make a property dangerous or potentially dangerous</li><li>make a property unfit to live in</li>
            <li>make a property unfit for the buyer’s purpose (if the buyer has told their industry member or the seller’s 
            industry member the purpose</li>
          </ul>
          **By law, sellers, and their real estate representatives, must disclose known material latent defects to potential buyers.<br></br>
          <br></br><u>Stigmatized Properties</u>: are unfavorable quality of the a property for reasons that are unrelated to the physical condition 
          or features, that may make the property less attractive or unattractive to some buyers. Stigmas include:
          <ul>
            <li>a suicide or death occurred in the property</li><li>the property was the scene of a major crime</li>
            <li>the address of the property has the wrong numerals</li><li>reports that a property is haunted</li>
          </ul>
          **If there are certain stigmas that matter to you, you need to talk to your real estate professional about them.
          Because these type of stigma are not material latent defects, the seller is not required to answer your questions, 
          but if they choose to answer – they must do so honestly. <b>If they refuse to answer, you’ll have to decide if you’re 
          comfortable proceeding without the information.</b><br></br>
          <br></br><b>Special listing situations</b><br></br>Some listings may say “as-is, where-is” or “judicial sale/foreclosure.”<br></br>
          <br></br><u>As-is, where-is sale</u>: there is no guarantee as to the property’s condition. There can be a significant amount of 
          risk in buying an as-is, where-is listing.<br></br>
          <br></br><u>Judicial sale</u>: is the sale of real property under the authority and supervision of the Court of Queen’s Bench. The property 
          may be offered at a lower than expected price, but there will be conditions attached to the home’s sale. For example:
          <ul>
            <li>you may not have access to condominium documents</li><li>your purchase may require approval through the Courts, which can significantly 
            delay it proceeding – if at all</li><li>there may be a set minimum deposit</li><li>it’s possible in a foreclosure situation, right up until possession day, 
            that the sale can fall through</li>
          </ul>
          **You’ll need to carefully weigh these restrictions against the fact the property may look like a good deal.
          </AccordComp>

          <AccordComp title="6. Making an Offer">
          You’ve found the perfect home, you have your deposit, and you’re ready to make an offer. There are a few things you can do to prepare:<br></br>
          <br></br><u>Review the Real Property Report</u>: a Real Property Report (RPR) is a legal document prepared by an Alberta Land Surveyor that 
          shows property boundaries and improvements (structures) relative to boundaries. You want to ensure an RPR has evidence of municipal 
          compliance, which confirms property improvements comply with municipal Bylaws and Regulations. If there is no RPR available, discuss 
          your options with your real estate professional and your lawyer.<br></br>
          <br></br><u>Search the address</u>: Search the address of the property or, in the case of a condominium, the name of the condominium 
          building/complex. An internet search can uncover quite a bit.<br></br>
          <br></br><u>Conduct a Historical listing search</u>: A historical listing search will show you the listing history of the property.<br></br>
          <br></br><u>Look into warranties and representations</u>: Alberta has an online public registry of homes covered by a new home warranty.<br></br>
          <br></br><b>Writing the Offer</b><br></br>
          <br></br><u>Offer price</u>: Your real estate professional will provide you with information to help you make an informed decision on what to offer.
          That information will include looking at comparable (recent sales of similar properties in the same neighborhood), and discussing your
          preferences. It’s your choice what to offer.<br></br>
          <br></br><u>Deposits</u>: A deposit can be a sign of how serious you are about the purchase. You need to have the deposit ready at the time
          of your offer.<br></br>
          <br></br><u>Terms</u>: A term is a detail in the purchase contract that the buyer and seller agree to. Terms include:
          <ul>
            <li><b>Possession date</b>: the date on which you will take possession of the property.</li>
            <li><b>Inclusions and exclusions</b>:<u>Inclusions</u> are items you want included in your purchase, typically appliances, 
              security systems, etc., and <u>exclusions</u> are items excluded from the purchase, for example if the sellers are taking the curtain 
              rods or TV wall mount with them. Inclusions and exclusions can be negotiated between the parties.
              <ul>
                <li><u>Attached goods</u> are items you cannot remove from the property without causing damage.</li>
                <li><u>Unattached goods</u> are movable items.</li>
                <li>In the absence of specific inclusions or exclusions in the offer to purchase, attached goods are typically 
                included in a sale while unattached goods are not.</li>
              </ul>
            </li>
          </ul>
          <u>Time for acceptance/expiry of offer</u>: You want to include an expiry date/time that:  
            <ul>
              <li>creates a sense of immediacy for the seller</li><li>may encourage the seller to review your offer before others</li>
              <li>removes the need for you to formally withdraw the offer at some point in the future</li>
            </ul>
            <u>Pre-possession inspection</u>: A pre-possession inspection term gives you the opportunity to view the property, with your 
            real estate professional, prior to possession. Such an inspection can help you confirm the property is in substantially 
            the same condition as it was when you viewed it and made your offer.<br></br>
            <br></br><u>Conditions</u>: Buyers often place conditions in their Offers to Purchase to protect their interests. 
            When you write a conditional Offer to Purchase, it means you want to buy the property but before making it a firm sale, 
            you want the ability and time to review or confirm information. Some common conditions include:
            <ul>
              <li>home inspection</li><li>financing</li><li>review of condominium documents (if buying a condominium).</li>
            </ul>
            The conditions you may want to include will differ depending on the type of property.<b>All conditions need to
            have an expiry date</b>. Make sure the expiry dates you include will provide you with enough time to satisfy the conditions. 
            <b>If you don’t waive your conditions in writing by their expiry date, the contract ends, and you and the seller have no further 
            obligations to each other</b>. If you are ready to <b>waive your conditions</b>, your real estate professional will provide you with the 
            required waiver, and <b>the purchase contract becomes final and binding.</b>
          </AccordComp>

          <AccordComp title="7. Negotiations">
          There will likely be some negotiation between you and the seller after you submit an Offer to Purchase. Sellers can outright accept or reject 
          your offer, make a counter offer, or ignore your offer completely.<br></br>
          <b>If the seller accepts your offer</b>, congratulations! If your Offer to Purchase contained conditions, your real estate professional will help
           you do what needs to be done in order to satisfy those conditions.<br></br>
          <b>If the seller rejects your offer</b>, you can either submit a new Offer to Purchase or look elsewhere.<br></br>
          <b>If the sellers provide a counter offer</b>, carefully review it and any terms and conditions. A counter offer likely contains a different selling 
          price, but the sellers may also counter on possession date, inclusions/exclusions, terms, or conditions. If you want to accept their counter offer, 
          make sure you review everything in it. If you want to change anything, you are essentially providing the sellers with a counter offer rather 
          than simply accepting their counter offer.<br></br>
          <b>If the seller ignores your Offer to Purchase</b>, it’s essentially the same as rejecting it. It’s up to you how you want to proceed. Do you want to 
          write a new Offer to Purchase or look for a different property?<br></br>
          <br></br><b>Multiple Offers</b><br></br>
          A <b>multiple offer</b> situation is <b>when multiple buyers submit an Offer to Purchase on the same property, at the same time.</b> It’s the seller who determines
          the process, including whether they want to disclose the multiple offer situation to potential buyers. If the seller discloses a multiple 
          offer situation, your real estate professional will:
          <ul>
            <li>tell you of the multiple offer situation</li><li>advise you of the seller’s options</li>
            <li>attempt to personally attend the offer presentations</li>
            <li>advise you of YOUR options, including:
              <ul>
                <li>increasing your offer prior to its presentation to the seller</li><li>leaving the offer as it is</li><li>withdrawing the offer</li>
                <li>reconsidering the fixtures, chattels, terms and conditions of the offer</li>
              </ul>
            </li>
            <li>advise on other considerations that could improve your position including:
              <ul>
                <li>a term or condition that will compel the seller to deal with the offer at the time of presentation or face withdrawal</li>
                <li>a requirement that the seller not disclose the price and terms to any other buyer or face withdrawal</li>
              </ul>
            </li>
          </ul>
          </AccordComp>

          <AccordComp title="8. Satisfying and Waiving Conditions">
          If your accepted offer was conditional, you need to try to satisfy your conditions.<br></br>
          <br></br><u>Financing</u>: If you included a financing condition, you need to confirm your financing before waiving this condition. 
          Before you waive your financing condition, your lender (or mortgage broker, if you’re working with one), will need documents 
          related to the property you’re buying. They will likely need:
          <ul>
            <li>a copy of your accepted Offer to Purchase</li><li>the listing sheet for the property you’re purchasing</li>
            <li>copy of the Real Property Report for the property (single family home/bareland condo)</li>
            <li>current title for the property</li>
          </ul>
          **Make sure you carefully review the mortgage commitment provided by your lender. Lenders sometimes include various 
          conditions in such a commitment. If your lender includes conditions, carefully consider whether you are ready to waive your 
          financing condition on the Offer to Purchase.<br></br>
          <br></br><u>Home inspection</u>: If you included a home inspection condition in your Offer to Purchase, it’s time to hire a home 
          inspector. <b>Home inspectors in Alberta need a Home Inspector’s Licence through Service Alberta. A home inspection is a professional 
          opinion on the condition of a property based on a non-invasive examination of its features and components.</b><br></br>
          The home inspector will give you a written report of their findings. This report will give you a sense of the condition 
          for a number of major components, which typically will include:
          <ul>
            <li>electrical</li><li>heating/air conditioning</li><li>roof</li><li>and foundation.</li>
            <li>The home inspector will also attempt to determine if the property has any moisture problems, 
              but they base that assessment only on what is visible.</li>
          </ul>
          It’s not unusual for a home inspection to uncover issues. Talk about the report with the home inspector and with your
          real estate professional.<br></br>
          <br></br>**Remember that when you include a home inspection as a condition, that condition only covers a home inspection
          by a home inspector.<b>If you would like additional inspections to occur, you need to include them as conditions in your Offer to Purchase.</b><br></br>
          <br></br><u>Condominium document review</u>: If you’re buying a condominium, you will likely include a condominium document review condition. 
          <b>Condominium documents relate to the operation of the condominium corporation. When you’re buying a condominium, you’re buying into 
          the corporation, common property, and shared responsibilities</b>. You want to <b>ensure</b> the <b>condominium corporation is financially stable, 
          managed well, and that the property is well maintained</b>. The seller is required to provide you with a number of condominium documents.
          These include but are not limited to:
          <ul>
            <li>current operating budget and fee schedule</li><li>current balance sheet</li><li>recent audited financial statements</li>
            <li>reserve fund study and plan</li><li>registered bylaws</li><li>AGM meeting minutes</li><li>six months of Board meeting minutes</li>
            <li>insurance certificate</li><li>management contract</li><li>post-tension cable report (if applicable)</li><li>any age restrictions in place</li>
            <li>engineering reports</li><li>parking detailsregistered condominium plan</li><li>listing sheet</li><li>offer to purchase</li>
          </ul>
          <b>There are professionals who will, for a fee, do this review for you, provide you with a summary of the documents, and identify 
          areas about which you might have concern</b>.  That review can uncover financial difficulties, unacceptable bylaws 
          (for example, restrictions as to size, number or type of pet), or necessary maintenance. Your reviewer will discuss their 
          findings with you – highlighting anything of concern. While you want to ensure the unit itself is what you’re looking for, and 
          that it will serve your purposes as a home, you also want to make sure you’re comfortable with the way the corporation is run.<br></br>
          <br></br><b>Conditions are met</b><br></br>
          If you meet all of your conditions and you’re prepared to proceed with your purchase, your real estate professional will 
          provide you with a waiver to sign.<br></br>
          <br></br><b>If you do not waive all of your conditions, your accepted Offer to Purchase is null and void.</b>
          The seller has no further obligations to you and you have no further obligations to the seller.
          <b>If you waive your conditions, and end up not proceeding with the purchase, you could lose your deposit and may be subject to legal action.</b><br></br>
          <br></br>**Only waive your conditions if and when you’re confident you’re going to proceed with the transaction.
          </AccordComp>

          <AccordComp title="9. Preparing for Possession">
          You will need to find and meet with a real estate lawyer. Your lawyer will go over the legal documents with you, 
          including the RPR and the title. They will also highlight any possible restrictions or caveats registered against 
          the property, discuss closing costs and disbursements, and have you sign the documents to transfer the property’s title 
          to you after your lawyer advances the purchase funds to the seller. <b>You’ll need to bring photo ID to your meeting with your lawyer</b>.
          Some lawyers require two pieces of identification.<br></br>
          <br></br>**Ask your lawyer specifically what forms of ID they accept.<br></br>
          <br></br><u>Title Insurance</u><br></br>
          Your lawyer will also discuss title insurance with you. <b>Title insurance works like a standard insurance policy, and protects against 
          future discoveries about the insured property</b>. It is a form of indemnity insurance for a mortgaged property. As insurance, it 
          covers the loss of an interest in a property due to legal defects. There are two types of title insurance:
          <ul>
            <li><u>A lender’s title insurance policy</u>: which the borrower usually pays for, is for the sole benefit of the mortgage lender. Your lender may 
            require title insurance and you, as the buyer, will have to pay for it. It is for the sole benefit of the lender.</li>
            <li><u>Owner’s title insurance</u>: is a separate policy where either the buyer or seller pays the insurance premiums to protect the buyer’s equity in 
            the property. This title insurance may relate to coverage for title and non-title issues. For example, title insurance can cover future title fraud
            (i.e. if through fraudulent transactions and paperwork, someone loses title to a property they legally own – title insurance can assist in getting 
            title back).</li>
          </ul>
          Once you’ve met with your lawyer and signed the documentation, including mortgage documents (if obtaining a mortgage), it’s really just 
          a matter of waiting for the possession date. During the waiting period, you can:
          <ul>
            <li>arrange for your mail to be forwarded to your new address through Canada Post</li>
            <li>schedule your utility hook-ups (electricity, gas, cable, etc.)</li>
            <li>arrange for home insurance to take effect as of possession day or when you move in, whichever is earlier</li>
            <li>hire a moving company (if using)</li>
          </ul>
          <b>Additional Closing Costs</b><br></br>
          Beyond your lawyer fees and title insurance costs (if any), you may also have to pay other closing costs, 
          including <b>property tax adjustments</b>. Because of the way property taxes work, you <b>may be required to reimburse the seller 
          some of the property taxes they’ve paid to their municipality in the current year</b>. Your lawyer can provide you with more information 
          about these costs. 
          </AccordComp>

          <AccordComp title="10. What to Expect on Possession Day">
          Typically, possession occurs – and you’ll get the keys – around noon on possession day.<br></br>
          <br></br><b>If you included a pre-possession inspection as a term in your Offer to Purchase</b>, your real estate
           professional will arrange that through the seller’s representative. It may take place the day before possession or the morning of;
          that’s up to you to negotiate with the seller as part of the offer process.<br></br>
          <br></br><b>During a pre-possession inspection you want to ensure the property is in substantially the same condition as it was when 
          you submitted your Offer to Purchase</b>. Attached goods should still be in place, as should any additional inclusions that you had in
          your Offer to Purchase. Appliances should be in the same working order, too.<br></br>
          <br></br>In terms of cleanliness, again, you should expect the property to be in similar condition as when you bought it, but there is 
          nothing that requires a seller to clean the property to a certain standard.<br></br>
          <br></br><b>Once you’ve taken possession</b>, if you find the <b>property is not in substantially the same condition or if something has been
           removed and should not have been, you need to call your lawyer as it will become a legal issue between you and the seller</b>. By the time
          you get the keys, the money has typically transferred to the seller. Your <b>real estate professional can attempt to discuss the matter 
          with the seller’s representative, but if things aren’t fixed to your satisfaction, your only recourse is to speak to your lawyer.</b>
          </AccordComp>
          </div>
        </div>
      </div>
    );
}

export default BuyersGuide;