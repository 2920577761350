import './Horizontalcomp.css';
import { useNavigate } from 'react-router-dom';


function HoriAccord3 (){
    const nav= useNavigate()

    return(
        <div className="containerhori">
            <div className="guide-wrap">
                <div onClick={() => nav("/buyers")}className="guide guide-1" style={{"background-image":"url('https://images.unsplash.com/photo-1560518883-ce09059eeffa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1973&q=80')"}}>  
                    <div className="name-container">
                        <h2 className="home-name">BUY</h2>
                    </div>           
                </div>
                <div onClick={()=>nav("/sellers")} className="guide guide-2" style={{"background-image":"url('https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2069&q=80')"}}>
                    <div className="name-container">
                        <h2 className="home-name">SELL</h2>
                    </div>             
                </div>    
            </div>
        </div>
    );
}
export default HoriAccord3;