import React from "react";
import { useNavigate } from "react-router-dom";

// Images
import houseLogo from '../img/house.svg';

// CSS
import './About.css';

function About() {
    const nav = useNavigate();

    return (
        <div className="about-container">
            <div className="heading">
                <h1>About</h1>
                <h1>J.L Realty</h1>
            </div>
            <div className="content-container">
                <img className="house-logo" src={houseLogo} alt="house logo" />
                <span className="mission-statement">
                    J.L Realty is dedicated to provide valuable quality real estate 
                    services to home buyers and sellers, by educating clients, 
                    keeping clients informed and well protected throughout the 
                    whole transaction. Feel free to contact, if you have any 
                    questions.
                    <div className="contact-button-container">
                        <button className="contact-button" onClick={() => nav('/contact')}>CONTACT</button>
                    </div>
                </span>
            </div>
            
        </div>
    );
}

export default About;