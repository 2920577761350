import React, { useState } from 'react';
import NavItem from "./NavItem";
import NavDropdownItem from "./NavDropdownItem";
import { useNavigate } from "react-router-dom";
import { FaBars } from 'react-icons/fa';
import logo from '../img/J_LREALTY.png';
import './Navbar.css';

function Navbar() {
    const nav = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const closeMenu = () => setIsMenuOpen(false);

    return (
        <div className="nav-container">
            <nav className="navbar">
                <img src={logo} alt="judy logo" className="logo" onClick={() => { nav("/"); closeMenu(); }} />
                
                <div className="hamburger-icon" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    <FaBars />
                </div>

                <div className={`nav-items ${isMenuOpen ? 'open' : ''}`}>
                    <NavItem name="Home" link="/" onClick={closeMenu} />
                    <NavItem name="About" link="/about" onClick={closeMenu} />
                    <NavItem name="Properties" link="/listings" onClick={closeMenu}>
                        <NavDropdownItem link="/search" onClick={closeMenu}>Search</NavDropdownItem>
                        <NavDropdownItem link="/listings" onClick={closeMenu}>Listings</NavDropdownItem>
                    </NavItem>
                    <NavItem name="Buyers" link="/buyers" onClick={closeMenu}>
                        <NavDropdownItem link="/buyer-s-form" onClick={closeMenu}>Buyers Form</NavDropdownItem>
                        <NavDropdownItem link="/buyer-s-guide" onClick={closeMenu}>Buyer's Guide</NavDropdownItem>
                        <NavDropdownItem link="/mortgage-calculator" onClick={closeMenu}>Mortgage Calculator</NavDropdownItem>
                        <NavDropdownItem link="/search" onClick={closeMenu}>Search</NavDropdownItem>
                        <NavDropdownItem link="/property-updates" onClick={closeMenu}>Property Updates</NavDropdownItem>
                    </NavItem>
                    <NavItem name="Sellers" link="/sellers" onClick={closeMenu}>
                        <NavDropdownItem link="/seller-s-form" onClick={closeMenu}>Sellers Form</NavDropdownItem>
                        <NavDropdownItem link="/seller-s-guide" onClick={closeMenu}>Seller's Guide</NavDropdownItem>
                        <NavDropdownItem link="/home-evaluation" onClick={closeMenu}>Home Evaluation</NavDropdownItem>
                        <NavDropdownItem link="/community-report" onClick={closeMenu}>Community Report</NavDropdownItem>
                    </NavItem>
                    <NavItem name="Request" link="/property-updates" onClick={closeMenu}>
                        <NavDropdownItem link="/property-updates" onClick={closeMenu}>Property Updates</NavDropdownItem>
                        <NavDropdownItem link="/home-evaluation" onClick={closeMenu}>Home Evaluation</NavDropdownItem>
                        <NavDropdownItem link="/community-report" onClick={closeMenu}>Community Report</NavDropdownItem>    
                    </NavItem>
                    <NavItem name="Contact" link="/contact" onClick={closeMenu} />
                    <NavItem name="Resources" link="/resources" onClick={closeMenu} />
                </div>
            </nav>
        </div>    
    );
}

export default Navbar;
