import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Components
import ScrollToTop from './components/ScrollToTop';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

// Pages
import Home from './pages/Home';
import About from './pages/About';
import Search from './pages/Search';
import Listing from './pages/Listing';
import Buyer from './pages/Buyers';
import BuyersGuide from './pages/BuyersGuide';
import MortgageCalculator from './pages/MortgageCalculator';
import Seller from './pages/Sellers';
import SellersGuide from './pages/SellersGuide';
import PropertyUpdates from './pages/PropertyUpdates';
import BuyersForm from './pages/BuyersForm';
import SellersForm from './pages/SellersForm';
import Contact from './pages/Contact';
import Resources from './pages/Resources';
import Homeevaluation from './pages/Homeevaluation';
import Communityreport from './pages/Communityreport';
import Thankyou from './pages/Thankyou';

// CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; 

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Navbar />
        <div className='pages-container'>
          <div className='pages'>
            <Routes>
              <Route path='/' Component={Home} />
              <Route path="/about" Component={About} />
              <Route path="/search" Component={Search} />
              <Route path="/listings" Component={Listing} />
              <Route path="/buyers" Component={Buyer}/>
              <Route path="/buyer-s-guide" Component={BuyersGuide} />
              <Route path="/mortgage-calculator" Component={MortgageCalculator}/>
              <Route path="/sellers" Component={Seller}/>
              <Route path="/seller-s-guide" Component={SellersGuide} />
              <Route path="/property-updates" Component={PropertyUpdates} />
              <Route path="/buyer-s-form" Component={BuyersForm} />
              <Route path="/seller-s-form" Component={SellersForm} />
              <Route path="/contact" Component={Contact} />
              <Route path="/resources" Component={Resources}/>
              <Route path="/home-evaluation" Component={Homeevaluation}/>
              <Route path='/community-report' Component={Communityreport}/>
              <Route path='/thank-you' Component={Thankyou}/>
            </Routes>
          </div>
        </div>
        <Footer />
      </Router>
    </div> 
  );
}

export default App;
