import React from "react";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import IconButton from '@mui/material/IconButton';

// CSS
import './Footer.css';

// Images
import profile from "../img/judypfp1.jpg";
import CompanyLogo from "../img/HopeStreetLogo.jpg";

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-left col-md-4 col-sm-6">
                <div className="profile-wrapper">
                    <img src={profile} alt="profile" />
                </div>
                <p style={{"margin-top":"-10px"}}>PARTNERED WITH</p>
                <a href="https://hopestreet.ca/" target="_blank" rel="noreferrer">
                    <img src={CompanyLogo} alt="company logo" /> 
                </a>
            </div>
            <div className="footer-center col-md-4 col-sm-6">
                <div>
                    <LocationOnIcon sx={{mr:3}} fontSize="large" />
                    <p><span>Unit #500, 602 12 Ave SW</span> 
                              Calgary, AB, Canada, T2R 1J3</p>
                </div>
                <div>
                    <a href="tel:4033972138">
                        <PhoneIcon sx={{mr:3}}
                                   fontSize="large"
                                   style={{color:"white"}}>
                        </PhoneIcon>
                        <p>(+1) 403 397 2138</p>
                    </a>
                </div>
                <div>
                    <a href="mailto:revazeko@mailgolem.com">
                        <EmailIcon sx={{mr:3}}
                                   fontSize="large"
                                   style={{color:"white"}}>         
                        </EmailIcon>
                        <p>jliu.estates@gmail.com</p>
                    </a>
                </div>
            </div>
            <div className="footer-right col-md-4 col-sm-6">
                <p className="about">
                    <span className="about-header">About the J.L Realty</span>J.L Realty is dedicated to provide valuable quality
                           real estate services to home buyers and sellers, by educating clients, keeping clients informed and well protected throughout the whole transaction. Feel free to contact, if you have any questions.
                </p>
                <div className="icons">
                    <IconButton color="inherit" edge="start">
                        <InstagramIcon fontSize="large"
                                       onClick={() => window.open('https://www.instagram.com/jliu.realty/', '_blank')}>
                        </InstagramIcon>
                    </IconButton>
                    <IconButton color="inherit"> 
                        <LinkedInIcon fontSize="large"
                                      onClick={() => window.open('https://www.linkedin.com/in/liuywjudy/', '_blank')}>
                        </LinkedInIcon>
                    </IconButton>
                    <IconButton color="inherit">
                        <FacebookIcon 
                            fontSize="large"
                            onClick={() => window.open('https://www.facebook.com/jliu.realty/', '_blank')}>
                        </FacebookIcon>
                    </IconButton>
                </div>
            </div>
        </footer>
    );
}

export default Footer;