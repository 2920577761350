import React from "react";
import ResourceComp from "../components/Resourcecomp";

//IMAGES
import DirectImage from "../img/DIRECT_LOGO.png";
import EnmaxImage from "../img/ENMAX_logo.jpg";
import CanadaImage from "../img/CANADAPOST_LOGO.png";
import TelusImage from "../img/Telus_Logo.png";
import ShawImage from "../img/SHAW_LOGO.png";
import RogersImage from "../img/ROGERS_LOGO.png";
import COCImage from "../img/COC_LOGO.png";
import QuantumImage from "../img/QUANTUM_LOGO.png"

//CSS
import './Resources.css';

function Resources (){
    const textStyle ={
        "text-align":"center"
    }

    return (
        <div className = "resource-page">
            <div className="heading">
                <h1 style={textStyle}>APPLICABLE <br></br>SERVICES</h1>
            </div>
            <div className="resource-flex">
                <div>
                    <ResourceComp 
                    title="Enmax"
                    servicetype="Utility Service"
                    pic={EnmaxImage}
                    link ="https://www.enmax.com/home/moving">
                    Contact for more information
                </ResourceComp>
                </div>
                <div>
                    <ResourceComp
                    title="Direct Energy"
                    servicetype="Utility Service"
                    pic={DirectImage}
                    link ="https://www.directenergyregulatedservices.com/">
                    Contact for more information
                    </ResourceComp>
                </div>
                <div>
                    <ResourceComp
                    title="Canada Post"
                    servicetype="Mailing Service"
                    pic={CanadaImage}
                    link ="https://www.canadapost-postescanada.ca/cpc/en/personal/receiving/manage-mail/mail-forwarding.page">
                    Contact for more information
                    </ResourceComp>
                </div>
                <div>
                    <ResourceComp
                    title="Teleus"
                    servicetype="Home Service"
                    pic={TelusImage}
                    link="https://www.telus.com/en/support/article/move-home-services">
                    Contact for more information
                    </ResourceComp>
                </div>
                <div>
                    <ResourceComp
                    title="Shaw"
                    servicetype="Home Service"
                    pic={ShawImage}
                    link="https://www.shaw.ca/moving/#/">
                    Contact for more information
                    </ResourceComp>
                </div>
                <div>
                    <ResourceComp
                    title="Rogers"
                    servicetype="Home Service"
                    pic={RogersImage}
                    link="https://www.rogers.com/">
                    Contact for more information
                    </ResourceComp>
                </div>
                <div>
                    <ResourceComp
                    title="City of Calgary"
                    servicetype="Garbage, Recycling & Compost Collection"
                    pic={COCImage}
                    link="https://www.calgary.ca/waste/residential/garbage-schedule.html">
                    Contact for more information
                    </ResourceComp>
                </div>
                <div>
                    <ResourceComp
                    title="Quantum Inspection"
                    servicetype="Home Service"
                    pic={QuantumImage}
                    link="https://quantuminspections.ca/">
                    Contact for more information
                    </ResourceComp>
                </div>
            </div>
        </div>
    );
}
export default Resources;