
//CSS
import './Thankyou.css'

function Thankyou() {
    

    return (
        <div className="thankSubmit">
            <h1>Thank You!</h1>
            <p>Your request has been sent. I will contact you as soon as possible.</p>
        </div>
    );
}
export default Thankyou;