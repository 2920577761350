import './Search.css';

function Search() {  
    return(
        <>
            <div className="heading">
                <h1>SEARCH</h1>
            </div>
            <div className="search-page">
                <div className="search-container">
                    <iframe 
                        className="search-app"
                        title="property search widget"
                        src="https://matrix.pillarnine.com/Matrix/public/IDX.aspx?idx=ea262af"
                        width="100%"   
                        height="100%" 
                        frameborder="1" 
                        marginwidth="0" 
                        marginheight="0"
                        borderradius="5%"
                    />
                </div>
            </div>
        </>
    );
}

    export default Search;