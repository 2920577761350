import React from "react";
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';


//CSS
import "./Resourcecomp.css";

//TODO: Add a prop that link to the service site.
function ResourceComp(props){


  return (
    <div className="resource-comp" >
        <div className="resource-title">{props.title}</div>
        <div className="type-of-service">{props.servicetype}<br></br> {props.children}</div>
        <a className="resource-button" href={props.link} target="_blank">
            <div className="send">
                <div className="vector" >
                    <LaunchRoundedIcon 
                        fontSize="small"
                        style={{ width: "18px",
                                 height: "18", 
                                 left:"3px", 
                                 top: "3px",
                                 position: "absolute",
                                 color:"white", 
                        }}>
                    </LaunchRoundedIcon>
                </div>
            </div>
            <div className="button-title">Website</div>
        </a>
        <a className="resource-pic" href={props.link} target="_blank">
            <img className="resource-image" src={props.pic}/>
        </a>
    </div>
  );
};

export default ResourceComp;