function BuyersForm() {
    return (
        <div>
            <div className="heading">
                <h1>Buyer's Form</h1>
            </div>
            <div style={{"text-align":"center", "height":"500px"}}>
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSexdsjLEpo3hLBWMJmbfTCWEFhhJpuioNIbWitX7FIkMWgm4g/viewform?embedded=true" 
                        width="100%"
                        height="100%"
                        frameborder="0" 
                        marginheight="0" 
                        marginwidth="0">Loading…
                </iframe>
            </div>
        </div>
    );
}

export default BuyersForm;