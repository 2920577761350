import './Horizontalcomp.css';
import { useNavigate } from 'react-router-dom';

function HoriAccord2 (){
    const nav = useNavigate();
    
    return(
        <div className="containerhori">
            <div className="guide-wrap">
                <div className="guide guide-1" 
                     style={{"background-image":"url('https://images.unsplash.com/photo-1520038410233-7141be7e6f97?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1174&q=80')"}}
                     onClick={() => nav("/seller-s-form")}>
                    <div className="name-container">
                        <h2 className="section-name">Seller's Form</h2>
                    </div>        
                    {/* <a href="#sellersForm" className="name-container">
                        <h2 className="section-name">Sellers Form</h2>
                    </a>            */}
                </div>
                <div className="guide guide-2" 
                    style={{"background-image":"url('https://images.unsplash.com/photo-1589998059171-988d887df646?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1776&q=80')"}}
                    onClick={()=>nav("/seller-s-guide")}>
                <div className="name-container">
                        <h2 className="section-name">Sellers Guide</h2>
                    </div>        
                </div>
                {/* Does not navigate anywhere */}
                <div class="guide guide-3" 
                    style={{"background-image":"url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"}}
                    onClick={()=>nav("/home-evaluation")}>
                <div className="name-container">
                        <h2 className="section-name">Home Evaluation</h2>
                    </div>  
                </div>
                {/* Does not navigate anywhere */}
                <div class="guide guide-4" 
                    style={{"background-image":"url('https://images.unsplash.com/photo-1504868584819-f8e8b4b6d7e3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1176&q=80"}}
                    onClick={()=>nav("/community-report")}>
                <div className="name-container">
                        <h2 className="section-name">Community<br></br>Report</h2>
                    </div>  
                </div>
                
            </div>
        </div>
    );
}

export default HoriAccord2;