import './PropertyUpdates.css';

function PropertyUpdates() {
    return(
        <>
        <div className="heading">
            <h1>Property Updates</h1>
        </div>
        <div className="property-update-container">
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeTW_E9RsiDkERR8pf02i3SmCaJn8NcY9nEfq-niz2bRnwMtg/viewform?embedded=true"
            width="1000" 
            height="4200" 
            frameborder="0" 
            marginheight="0" 
            marginwidth="0">Loading…
        </iframe>
        </div>
        </>
    );
}

export default PropertyUpdates;